<template>
    <Editor :url="url" :fields="fields"
            icon="mdi-counter"
            testo-list="Livelli di conformità"
            testo-insert="Nuovo livello"
            testo-update="Modifica livello"
            testo-delete="Vuoi cancellare il livello?">
        <template #form="{instance}">
            <v-slider v-model="instance.punteggio"
                      :label="'Punteggio: ' + instance.punteggio"
                      min="0" max="5"></v-slider>
            <v-slider v-model="instance.percentuale"
                      :label="'Percentuale: ' + instance.percentuale + '%'"
                      min="0" max="100" step="5"></v-slider>
            <v-text-field v-model="instance.descrizione" label="Descrizione"></v-text-field>
        </template>
    </Editor>
</template>


<script>
import Editor from "@/components/Editor";

export default {
    data: () => ({
        fields: [
            {text: 'Descrizione', value: 'descrizione'},
            {text: 'Punteggio', value: 'punteggio'},
            {text: 'Percentuale', value: 'percentuale'},
        ]
    }),
    computed: {
        url() {
            return '/checklist/livello/';
        }
    },
    components: {
        Editor
    }
}
</script>